import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogComponent, DialogData } from '../../dialog/dialog.component';
import { IDialog } from '../../dialog/dialog.interface';
import { IdName } from '../../models/id-name.model';
import { DropdownItem } from '../../dropdown/dropwdown-item.model';

export interface AssignmentInputDialogData extends DialogData {
  items: IdName<number>[];
  lazyLoadItems?: (page: number) => Observable<DropdownItem[]>;
}

@Component({
  selector: 'th-assignment-input-dialog',
  templateUrl: './assignment-input-dialog.component.html',
  styleUrls: ['./assignment-input-dialog.component.scss'],
})
export class AssignmentInputDialogComponent
  implements IDialog<AssignmentInputDialogData, { id: number | null }>, OnInit
{
  subject = new BehaviorSubject({ id: null as unknown as null | number });
  dialogData!: AssignmentInputDialogData;
  @ViewChild('dialog') dialog!: DialogComponent;

  items!: IdName<number>[];
  lazyLoadItems: ((page: number) => Observable<DropdownItem[]>) | null = null;
  id!: number | null;

  ngOnInit(): void {
    this.items = this.dialogData.items;
    this.lazyLoadItems = this.dialogData.lazyLoadItems ?? null;
  }

  public confirm(): void {
    this.subject.next({ id: this.id });
    this.dialog.dialogRef.nativeElement.close();
  }

  public cancel(): void {
    this.subject.next({ id: null });
    this.dialog.dialogRef.nativeElement.close();
  }
}
