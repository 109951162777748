<div
  class="rounded-md bg-dark-gray-750 flex gap-2 py-2 px-3 text-sm items-center"
>
  <ng-container *ngFor="let item of navigationItems; let last = last">
    <span
      *ngIf="item.icon"
      class="material-icons icon material-symbols-outlined text-base text-dark-gray-200"
      >{{ item.icon }}</span
    >
    <div
      (click)="!last ? navigate(item.link) : null"
      [class.cursor-pointer]="!last"
      [class.text-dark-gray-200]="!last"
      [class.text-dark-gray-400]="last"
    >
      {{ $any(item.title$ | async) | translate }}
    </div>
    <span
      *ngIf="!last"
      class="material-icons icon material-symbols-outlined text-dark-gray-400"
      >chevron_right</span
    >
  </ng-container>
</div>
