<th-dialog
  (confirm)="confirm()"
  (cancel)="cancel()"
  [dialogData]="dialogData"
  [isConfirmAllowed]="!!id"
  #dialog
>
  <div class="grid grid-cols-[1fr_4fr] gap-4 items-center my-5">
    <label>{{ 'project.odoo-item' | translate }}</label>
    <th-dropdown
      class="grow"
      [items]="items"
      [isClearable]="false"
      [noMargin]="true"
      [lazyLoadItems]="lazyLoadItems"
      [(value)]="id"
    ></th-dropdown>
  </div>
</th-dialog>
