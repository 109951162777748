<ng-template
  #menuItems
  let-items="items"
  let-name="name"
  let-withMargin="withMargin"
>
  <li [class.mt-4]="withMargin">
    <p
      *ngIf="
        name && items.length > 0 && !isSidebarCollapsed && items.length > 0
      "
      class="text-sm font-medium"
    >
      {{ 'general.' + name + '-navigation-title' | translate }}
    </p>
    <ul role="list" class="mt-2 space-y-1">
      <li *ngFor="let item of items">
        <a
          [href]="item.link"
          class="nav-item group flex gap-x-3 rounded-md p-2 text-base leading-6 font-normal"
          [class.selected]="item.selected && !item.childs?.length"
          [class.highlighted]="item.selected && item.childs?.length"
        >
          <span class="material-symbols-outlined">{{ item.icon }}</span>
          <span *ngIf="!isSidebarCollapsed" class="truncate">{{
            $any(item.title$ | async) | translate
          }}</span>
        </a>
        <ng-container *ngIf="!isSidebarCollapsed">
          <ng-container *ngIf="item.childs?.length === 1">
            <div class="pl-11">
              <div class="text-dark-primary py-2">
                {{ $any(item.childs[0].title$ | async) | translate }}
              </div>
              <ng-container
                *ngTemplateOutlet="
                  subItems;
                  context: { $implicit: item.childs[0].childs }
                "
              ></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="item.childs?.length > 1">
            <ng-container
              *ngTemplateOutlet="subItems; context: { $implicit: item.childs }"
            >
            </ng-container>
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </li>
</ng-template>

<ng-template #subItems let-childs>
  <div *ngFor="let pageChild of childs; let last = last" class="flex pl-4">
    <div class="bg-dark-gray-400 w-px" [class.last-line]="last"></div>
    <div class="bg-dark-gray-400 h-px w-2.5 self-center"></div>
    <div
      class="ml-1 p-2 my-0.5 rounded-md grow cursor-pointer subitem"
      [class.selected]="pageChild.selected"
      [class.text-dark-primary]="pageChild.selected"
      [class.text-dark-gray-400]="!pageChild.selected"
      (click)="navigate(pageChild.link)"
    >
      {{ $any(pageChild.title$ | async) | translate }}
    </div>
  </div>
</ng-template>

<div class="base-container">
  <!-- 20rem; -->
  <div
    class="fixed inset-y-0 z-50 flex flex-col"
    [ngClass]="isSidebarCollapsed ? 'w-16' : 'w-80'"
  >
    <div
      class="flex grow flex-col gap-y-5 bg-dark-gray-950 pt-5 h-full"
      [class.transparent-panel]="themeService.isOnLandingPage"
      [ngClass]="isSidebarCollapsed ? 'items-center' : ''"
    >
      <div
        class="logo-and-collapse-container flex h-16 shrink-0 cursor-pointer items-center justify-center px-5"
        [ngClass]="isSidebarCollapsed ? 'justify-center' : 'justify-between'"
      >
        <div *ngIf="!isSidebarCollapsed" class="flex items-center">
          <div>
            <img
              class="h-12 w-auto"
              [src]="
                themeService.isOnLandingPage
                  ? 'assets/townhall.svg'
                  : 'assets/nerdware_light.svg'
              "
              alt="Nerdware"
              (click)="goToHome()"
            />
          </div>

          <div>
            <p
              class="pl-2.5 text-lg font-normal cousine text-dark-gray-600"
              (click)="goToHome()"
            >
              {{ titleKey | translate }}
            </p>
          </div>
        </div>
        <div
          class="collapse-wrapper h-10 w-10 flex justify-center items-center rounded-md"
        >
          <a
            (click)="toggleSidebar()"
            class="h-10 w-10 flex justify-center items-center"
          >
            <img
              class="h-10 w-auto collapse-icon h-4 w-2.5"
              src="assets/collapse_nav_icon.svg"
              alt="collapseNavBar"
              [ngStyle]="
                isSidebarCollapsed ? { transform: 'rotate(180deg)' } : {}
              "
          /></a>
        </div>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul
          role="list"
          class="flex flex-1 flex-col gap-y-7 overflow-y-auto overflow-x-hidden pr-5 pl-5"
          [ngClass]="isSidebarCollapsed ? 'items-center' : ''"
        >
          <ng-container *ngIf="app !== 'global'">
            <ng-container
              *ngTemplateOutlet="
                menuItems;
                context: { items: filteredScopedItems, name: app }
              "
            >
            </ng-container>
          </ng-container>
          <ng-container
            *ngTemplateOutlet="
              menuItems;
              context: {
                items: filteredGlobalItems,
                name: app !== 'global' ? 'global' : '',
                withMargin: app !== 'global'
              }
            "
          >
          </ng-container>
          <li
            class="-mx-6 mt-auto items-center justify-center flex flex-col gap-10 mb-5 px-7"
          >
            <div
              *ngIf="emulatedFullName$ | async as fullName"
              [ngClass]="
                isSidebarCollapsed
                  ? ''
                  : 'profile-menu-container flex bg-dark-gray-900 rounded-md items-center p-2 w-full'
              "
            >
              <div
                class="flex items-center text-base font-normal leading-6 grow"
                [ngClass]="isSidebarCollapsed ? '' : 'pl-2'"
              >
                <div
                  *ngIf="!isSidebarCollapsed"
                  class="user-info flex flex-col px-2 py-2 grow"
                >
                  <span
                    class="text-xs leading-4 text-dark-gray-600"
                    aria-hidden="true"
                    >{{ 'Emuliert als' }}</span
                  >
                  <span
                    class="text-base leading-6 text-dark-gray-400"
                    aria-hidden="true"
                    >{{ fullName }}</span
                  >
                </div>
                <a
                  *ngIf="!isSidebarCollapsed"
                  class="cursor-pointer flex items-center text-base font-normal leading-6"
                  (click)="stopEmulation()"
                >
                  <span
                    class="text-dark-gray-400 material-icons icon material-symbols-outlined cursor-pointer"
                  >
                    cancel
                  </span></a
                >
              </div>
            </div>

            <div
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
              #menuToggleRef
              [ngClass]="
                isSidebarCollapsed
                  ? ''
                  : 'profile-menu-container flex bg-dark-gray-900 rounded-md items-center p-2'
              "
            >
              <div *ngIf="!isSidebarCollapsed" class="h-10 w-10 rounded-full">
                <img [src]="profilePicture$ | async" alt="profile-picture" />
              </div>
              <div
                class="flex items-center text-base font-normal leading-6 grow"
                [ngClass]="isSidebarCollapsed ? '' : 'pl-2'"
              >
                <a
                  *ngIf="isSidebarCollapsed"
                  class="nav-item cursor-pointer flex items-center mb-5 text-base font-normal leading-6 p-1 rounded-md"
                  (click)="toggleMenu()"
                >
                  <div class="h-10 w-10 rounded-full">
                    <img
                      [src]="profilePicture$ | async"
                      alt="profile-picture"
                    />
                  </div>
                </a>

                <div
                  *ngIf="!isSidebarCollapsed"
                  class="user-info flex flex-col px-2 py-2 grow"
                >
                  <span
                    class="text-base leading-6 text-dark-gray-400"
                    aria-hidden="true"
                    >{{ fullName$ | async }}</span
                  >
                  <span
                    class="text-xs leading-4 text-dark-gray-600"
                    aria-hidden="true"
                    >{{ emailAddress$ | async }}</span
                  >
                </div>
                <a
                  *ngIf="!isSidebarCollapsed"
                  class="nav-item cursor-pointer flex items-center text-base font-normal leading-6"
                  (click)="toggleMenu()"
                >
                  <span
                    class="material-icons icon material-symbols-outlined cursor-pointer"
                  >
                    more_vert
                  </span></a
                >
              </div>
            </div>
          </li>
        </ul>
        <ng-template
          cdk-connected-overlay
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-backdrop'"
          (backdropClick)="toggleMenu()"
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isProfileMenuVisible"
          #cdkConnectedOverlay="cdkConnectedOverlay"
        >
          <div class="profile-menu-overlay">
            <li
              (click)="
                !helperService.isHelpModeEnabled$.value
                  ? helperService.toggleTooltipsOn()
                  : helperService.toggleTooltipsOff()
              "
            >
              <span
                class="material-icons icon material-symbols-outlined cursor-pointer text-dark-gray-400"
              >
                info
              </span>
              {{
                !helperService.isHelpModeEnabled$.value
                  ? ('info.on' | translate)
                  : ('info.off' | translate)
              }}
            </li>
            <li (click)="logout()">
              <span
                class="material-icons icon material-symbols-outlined cursor-pointer text-dark-gray-400"
              >
                logout
              </span>
              {{ 'sidebar.logout' | translate }}
            </li>
          </div>
        </ng-template>
      </nav>
    </div>
  </div>
  <main
    class="base-container"
    [ngClass]="isSidebarCollapsed ? 'pl-16' : 'uncollapsed-sidebar-padding'"
  >
    <ng-content></ng-content>
  </main>
</div>
