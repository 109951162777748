<th-dropdown
  *ngIf="isInitialized && (!isDisabled || forceDropdown)"
  [optionTemplate]="optionTemplate"
  [isDisabled]="isDisabled"
  [itemTemplate]="itemTemplate"
  [(value)]="selectedTagIds"
  [isMultiple]="true"
  [items]="allTags"
  [isClearable]="false"
  [placeholder]="placeholder"
  [labelText]="labelText"
  [tooltipText]="tooltipText"
  [noMargin]="noMargin"
  [addTag]="true"
  [createNewOption]="createNewTag.bind(this)"
  (valueChange)="tagsChanged($event)"
>
</th-dropdown>
<div *ngIf="isDisabled && !forceDropdown" class="disabled-tags relative">
  <label *ngIf="labelText" class="text-base font-medium input-label"
    >{{ labelText }}
  </label>
  <div class="tag-container flex items-center gap-2.5">
    <ng-container *ngFor="let tag of value.slice(0, tagsToDisplay)">
      <ng-container
        *ngTemplateOutlet="
          itemTemplate;
          context: { item: tag, hideClear: true }
        "
      ></ng-container>
    </ng-container>
    <th-counter-circle
      *ngIf="value.length > tagsToDisplay"
      value="+{{ value.length - tagsToDisplay }}"
      [tp]="remainingTags"
    ></th-counter-circle>
  </div>
</div>

<ng-template let-item="item" #optionTemplate>
  <div
    class="tag max-h-5 rounded-lg flex justify-center items-center"
    [style.background-color]="item.color"
  >
    <p>{{ item.name }}</p>
  </div>
</ng-template>

<ng-template let-item="item" let-hideClear="hideClear" #itemTemplate>
  <div
    class="tag rounded-lg flex justify-center items-center"
    [style.background-color]="item.color"
  >
    <p class="text-sm">{{ item.name }}</p>
    <p
      *ngIf="!hideClear"
      (click)="removeTag(item)"
      aria-hidden="true"
      class="ng-value-icon right ng-star-inserted rounded-r-lg hover:rounded-r-lg"
    >
      ×
    </p>
  </div>
</ng-template>
