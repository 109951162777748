import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private isSmallScreenSubject = new BehaviorSubject<boolean>(false);
  isSmallScreen$ = this.isSmallScreenSubject.asObservable();

  private isSmallBreadcrumbSubject = new BehaviorSubject<boolean>(false);
  isSmallBreadcrumb$ = this.isSmallBreadcrumbSubject.asObservable();

  constructor() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this));
  }

  private checkScreenSize(): void {
    const isSmallScreen = window.innerWidth <= 1260;
    this.isSmallScreenSubject.next(isSmallScreen);

    const isSmallBreadcrumb = window.innerWidth <= 400;
    this.isSmallBreadcrumbSubject.next(isSmallBreadcrumb);
  }
}
