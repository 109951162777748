import {
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  debounceTime,
  filter,
  skip,
  tap,
} from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { DropdownItem } from '../dropdown/dropwdown-item.model';
import { IdName } from '../models/id-name.model';
import { HelperService } from '../services/helper.service';
import { AssignmentInputDialogComponent } from './dialog/assignment-input-dialog.component';

@Component({
  selector: 'th-assignment-input',
  templateUrl: './assignment-input.component.html',
  styleUrls: ['./assignment-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssignmentInputComponent),
      multi: true,
    },
  ],
})
export class AssignmentInputComponent
  implements OnDestroy, OnInit, ControlValueAccessor
{
  private _value = new BehaviorSubject<IdName<number> | null>(null);
  private valueSubscription!: Subscription;
  private items!: IdName<number>[];
  public isInitialized = false;

  @Input()
  get value(): number | null {
    return this._value?.value?.id ?? null;
  }
  set value(value: number | null) {
    if (this._value.value?.id === value) {
      return;
    }
    this._value.next(this.getItem(value));
  }

  @Input({ required: true }) items$!: Observable<IdName<number>[]>;
  @Input() lazyLoadItems!: (page: number) => Observable<DropdownItem[]>;
  @Input() labelText = '';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() tooltipText? = '';
  @Input() noMargin = false;
  @Input() debounce = 0;
  @Input() emitInitialValueChange = true;
  @Output() valueChange = new EventEmitter<number | null>();
  @Output() accept = new EventEmitter<number | null>();

  get selectedName(): string {
    return this._value.value?.name ?? '';
  }

  constructor(
    public helperService: HelperService,
    private applicationRef: ApplicationRef,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    this.items$
      .pipe(
        tap((items) => (this.items = items)),
        tap(() => this._value.next(this.getItem(this._value.value?.id))),
        tap(() => {
          if (!this.isInitialized && this.emitInitialValueChange) {
            this.valueChange.emit(this.value);
          }

          this.isInitialized = true;
          this._value.next(this.getItem(this.value));
          this.initValueSubscription();
        }),
      )
      .subscribe();
  }

  private initValueSubscription(): void {
    this.valueSubscription = this._value
      .pipe(
        debounceTime(this.debounce),
        skip(1),
        tap((v) => this.valueChange.emit(v?.id)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.valueSubscription?.unsubscribe();
  }

  private getItem(id: number | null | undefined): IdName<number> | null {
    const x = this.items?.find((i) => i.id === id);
    if (!x) {
      if (id) {
        return { id: id as number, name: '' };
      } else {
        return null;
      }
    }

    return x ?? null;
  }

  public openDialog(): void {
    DialogComponent.show(AssignmentInputDialogComponent, this.applicationRef, {
      title: 'project.choose-odoo-item',
      type: 'question',
      items: this.items,
      lazyLoadItems: this.lazyLoadItems,
    })
      .pipe(
        filter((result) => !!result.id),
        tap((result) => {
          this.value = result.id;
          this.onChange(this.value);
        }),
        tap((result) => this.accept.emit(result.id)),
      )
      .subscribe();
  }
}
