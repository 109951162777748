import { ApplicationRef, DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Observable, filter, map, of, switchMap, tap } from 'rxjs';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { ObservableCachingHelper } from '../../shared/helpers/observable-caching.helper';
import { BaseState } from '../../shared/state/base.state';
import { Selector } from '../../shared/state/types';
import { CompanyService } from '../components/services/company.service';
import { ExternalToolsService } from '../components/services/external-tools.service';
import { Company } from '../models/company.model';
import { ExternalTool } from '../models/external-tool.model';

interface CompanyMgmtState {
  company: Company;
  externalTools: ExternalTool[];
  isEditModeActive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyMgmtStateService extends BaseState<CompanyMgmtState> {
  companyOptions$: Observable<Company[]> = this.companyService
    .getCompanies(null as never, { page: 1, size: 50 })
    .pipe(map((result) => result.content));

  isEditModeActive$: Selector<boolean> = this.select(
    ({ isEditModeActive }) => isEditModeActive,
  );
  company$: Selector<Company> = this.select(({ company }) => company);
  externalTools$: Selector<ExternalTool[]> = this.select(
    ({ externalTools }) => externalTools,
  );

  private cachingHelper = new ObservableCachingHelper<Company[]>(
    this.destroyRef,
  );

  public get company(): Company {
    return this.selectSnapshot(({ company }) => company) as Company;
  }

  constructor(
    private destroyRef: DestroyRef,
    private companyService: CompanyService,
    private externalToolsService: ExternalToolsService,
    private router: Router,
    private applicationRef: ApplicationRef,
  ) {
    super({
      company: {
        name: '',
        parentCompanyId: null,
        domain: '',
        id: 0,
        odooCompanyId: null,
        importOdooEmployees: false,
        employeeCount: null,
      },
      externalTools: [],
      isEditModeActive: false,
    });
  }

  public toggleEditMode(): void {
    this.updateState((state) => {
      state.isEditModeActive = !state.isEditModeActive;
    });
  }

  public getCompanyName(id: number): Observable<string> {
    return this.cachingHelper
      .cacheSetOnMiss$(this.companyOptions$, ['companies'])
      .pipe(
        map((companies) => companies.find((company) => company.id === id)),
        map((company) => company?.name ?? ''),
      );
  }

  public loadCompany(id: number): void {
    const company$ = id
      ? this.companyService.getCompany(id)
      : of<Company>({
          id: 0,
          name: '',
          domain: '',
          parentCompanyId: null,
          odooCompanyId: null,
          importOdooEmployees: false,
          employeeCount: null,
        });

    company$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((company) =>
      this.updateState((state) => {
        state.company = company;
      }),
    );
  }

  public loadExternalTools(companyId: number): void {
    this.externalToolsService
      .getExternalToolsByCompany(companyId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) =>
        this.updateState((state) => {
          state.externalTools = response.content;
        }),
      );
  }

  public save(company: Company, tools: ExternalTool[]): void {
    this.saveCompany(company)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((response) => {
          if (tools.length > 0) {
            tools.forEach((t) => {
              t.companyId = response.id;
              this.saveExternalTool(t);
            });
          }

          this.updateState((state) => {
            state.company = response;
          });

          this.router.navigate(['companies', 'edit', response.id]);
        }),
      )
      .subscribe();
  }

  private saveCompany(company: Company): Observable<Company> {
    const isNewCompany = !company.id;
    const action$ = isNewCompany
      ? this.companyService.addCompany(company)
      : this.companyService.editCompany(company);

    return action$;
  }

  public triggerImport(company: Company): void {
    this.companyService
      .triggerImport(company)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private saveExternalTool(externalTool: ExternalTool): void {
    const action$ = externalTool.id
      ? this.externalToolsService.editExternalTool(externalTool)
      : this.externalToolsService.addExternalTool(externalTool);

    action$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((response) => {
      this.updateState((state) => {
        if (externalTool.id) {
          const index = state.externalTools.findIndex(
            (tool) => tool.id === response.id,
          );
          state.externalTools[index] = response;
        } else {
          state.externalTools.push(response);
        }
      });
    });
  }

  deleteExternalTool(externalTool: ExternalTool) {
    const action$ = externalTool.id
      ? DialogComponent.show(ConfirmationDialogComponent, this.applicationRef, {
          title: 'general.confirmation',
          description: 'external-tools.deletion-question',
          type: 'warning',
        }).pipe(
          filter((confirm) => confirm),
          switchMap(() =>
            externalTool.id
              ? this.externalToolsService.deleteExternalTool(
                  externalTool.id,
                  externalTool.companyId,
                )
              : of(null),
          ),
        )
      : of(null);

    action$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.updateState((state) => {
        state.externalTools = state.externalTools.filter(
          (tool) => tool.id !== externalTool.id,
        );
      });
    });
  }
}
