import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IdName } from '../models/id-name.model';
import { Tag } from '../models/tag.model';
import { TagService } from '../services/tag.service';

export const tagColors = {
  green: { name: 'var(--dark-green-300)' },
  yellow: { name: 'var(--dark-yellow-300)' },
  orange: { name: 'var(--dark-orange-300)' },
  pink: { name: 'var(--dark-pink-400)' },
  rose: { name: 'var(--dark-rose-300)' },
  blue: { name: 'var(--dark-blue-300)' },
  indigo: { name: 'var(--dark-indigo-300)' },
  violet: { name: 'var(--dark-violet-300)' },
};

@Component({
  selector: 'th-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
  private _value = new BehaviorSubject<Tag[]>(this.value);
  public isInitialized = false;
  public allTags: Tag[] = [];
  public selectedTagIds: number[] = [];

  public tagColors = Object.values(tagColors);

  @Output() valueChange = new EventEmitter<Tag[]>();
  @Input() type!: 'project_employee' | 'project';
  @Input() placeholder = '';
  @Input() tagsToDisplay!: number;
  @Input() labelText = '';
  @Input() tooltipText = '';
  @Input() isDisabled = false;
  @Input() forceDropdown = false;
  @Input() noMargin = false;
  @Input()
  get value(): Tag[] {
    return this._value?.value;
  }
  set value(value: Tag[]) {
    if (this._value.value === value) {
      return;
    }
    this._value.next(value);
  }

  public get remainingTags(): string {
    return this.value
      .slice(this.tagsToDisplay)
      .map((t) => t.name)
      .join(', ');
  }

  constructor(private tagService: TagService) {}

  ngOnInit(): void {
    if (this.isDisabled && !this.forceDropdown) {
      this.selectedTagIds = this.value?.map((v) => v.id).filter(Boolean) ?? [];
      this.isInitialized = true;
    } else {
      this.tagService.getAllTags(this.type).subscribe((tags) => {
        this.allTags = tags;
        this.selectedTagIds =
          this.value?.map((v) => v.id).filter(Boolean) ?? [];
        this.isInitialized = true;
      });
    }
  }

  tagsChanged(tagIds: number[]): void {
    this.valueChange.emit(
      tagIds.map((id) => this.allTags.find((t) => t.id === id) as Tag),
    );
  }

  createNewTag(term: string | IdName<string | number>): Tag {
    let tag: Tag;
    if (typeof term === 'string') {
      tag = {
        id: 0,
        name: term,
        entityType: this.type,
        color: this.generateColor(),
      };
    } else {
      tag = {
        id: 0,
        name: term.name,
        entityType: this.type,
        color: this.generateColor(),
      };
    }

    this.allTags.push(tag);
    return tag;
  }

  removeTag(tag: Tag): void {
    setTimeout(
      () =>
        (this.selectedTagIds = this.selectedTagIds.filter((t) => t !== tag.id)),
    );
  }

  private generateColor(): string {
    return this.tagColors[Math.floor(Math.random() * 9)].name;
  }
}
