import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { tagColors } from '../tags/tags.component';

interface Bar {
  value: number;
  percentage: number;
  text: string;
  color?: string;
  suffix?: string;
}

@Component({
  selector: 'th-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent implements OnChanges {
  @Input({ required: true }) bars!: {
    value: number;
    text: string;
    color?: string;
    suffix?: string;
  }[];
  @Input() showLegend = false;
  @Input() showInlineValues = false;

  public percentageBars!: Bar[];

  private tagColors = Object.values(tagColors);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['bars']) {
      const sum = this.bars
        .map((b) => b.value)
        .reduce((prev, current) => prev + current, 0);

      this.percentageBars = this.bars.map((b, index) => ({
        value: b.value,
        percentage: (b.value / sum) * 100,
        text: b.text,
        color: b.color ?? this.tagColors[index % this.tagColors.length].name,
        suffix: b.suffix,
      }));
    }
  }
}
