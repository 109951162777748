import {
  ApplicationRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Observable, switchMap, tap } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { TableService } from '../interfaces/table-service.interface';
import { IdName } from '../models/id-name.model';
import { PagedData } from '../models/paged-data.model';
import { Condition, Query, Sorting, Where } from '../models/query.model';
import { TableStorageService } from '../services/table-data.service';
import { TableConfigurationComponent } from './components/table-configuration/table-configuration.component';
import { TableFilterDialogComponent } from './components/table-filter-dialog/table-filter-dialog.component';

export interface PrimeNgColumn {
  propertyKey: string;
  filterKey?: string;
  label?: string;
  filterType?: 'text' | 'dropdown';
  sortable?: boolean;
  filterable?: boolean;
}

export interface InternalTableColumn extends PrimeNgColumn {
  isVisible: boolean;
}

export interface DisplayableTableColumn {
  propertyKey: string;
  filterKey?: string;
  isVisible: boolean;
}

export interface TableConfiguration {
  columns: PrimeNgColumn[];
}

export type SortState = { columnKey?: string; ascending: boolean };
type FilterState = { [key: string]: unknown };

@Component({
  selector: 'th-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit {
  @Input() isRowSelectionEnabled = false;
  @Input() isColumnFilteringEnabled = true;
  @Input() config!: TableConfiguration;
  @Input() isFullScreenActive = false;

  @Input() service!: TableService;
  @Input() dataItems$!: Observable<unknown[]>;

  @Input({ required: true }) rowTemplate!: TemplateRef<unknown>;
  @Input() footerTemplate!: TemplateRef<unknown>;
  @Input() preRowsTemplate: TemplateRef<unknown> | null = null;
  @Input() postRowsTemplate: TemplateRef<unknown> | null = null;
  @Input() customToolBarTemplate: TemplateRef<unknown> | null = null;
  @Input() serviceContext?: object;
  @Input() skipInitialLoad = false;
  @Input() showTableHeader = true;
  @Input() initialSorting?: SortState;
  @Input() isTableHeaderCentered = false;
  @Input() isTableHeaderAlignedRight = false;
  @Output() dataLoading = new EventEmitter<Query | null>();
  @Output() dataLoaded = new EventEmitter<unknown[]>();
  @Output() fullScreenToggle = new EventEmitter<boolean>();
  @Input() uniqueTableName!: string;
  @Input() isPaginatorVisible = false;
  @Input() isVirtualScrollingEnabled = false;
  @Input() translationKeyPrefix!: string;

  @ViewChild('pageDropdown', { static: false })
  pageDropdown!: DropdownComponent;
  public internalTableColumns!: InternalTableColumn[];

  private lastServiceContextParam?: object;

  pageSize = 10;
  pageOptions = [
    { id: 10, name: '10' },
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 100, name: '100' },
  ] as IdName<number>[];
  totalRowCount = 0;
  firstRowIndex = 0;

  rows: unknown[] = [];
  selectedRows: number[] = [];
  sortState: SortState = { columnKey: '', ascending: false };
  filterState: FilterState = {};

  @HostBinding('class.fullscreen') get fullscreen() {
    return this.isFullScreenActive;
  }

  public get visibleColumns(): InternalTableColumn[] {
    return this.internalTableColumns.filter((column) => column.isVisible);
  }

  public get pageReport(): string {
    return `${this.firstRowIndex + 1} - ${Math.min(
      this.firstRowIndex + this.pageSize,
      this.totalRowCount,
    )} of ${this.totalRowCount}`;
  }

  public get isFilterActive(): boolean {
    return Object.keys(this.filterState ?? {}).length > 0;
  }

  public get isAnyColumnFilterable(): boolean {
    return this.visibleColumns?.some((c) => c.filterable);
  }

  public get isAnyColumnSortable(): boolean {
    return this.visibleColumns?.some((c) => c.sortable);
  }

  public get hasCustomToolbar(): boolean {
    return (
      !!this.customToolBarTemplate &&
      this.customToolBarTemplate.elementRef !== null
    );
  }

  public get isTableCaptionVisible(): boolean {
    return (
      this.isColumnFilteringEnabled ||
      this.isAnyColumnFilterable ||
      this.hasCustomToolbar
    );
  }

  constructor(
    public tableStorageService: TableStorageService,
    private applicationRef: ApplicationRef,
  ) {}

  ngOnInit(): void {
    this.internalTableColumns = this.config.columns.map((column) => {
      return {
        ...column,
        sortable: column.sortable !== undefined ? column.sortable : true,
        filterType:
          column.filterType !== undefined ? column.filterType : 'text',
        filterable: column.filterable !== undefined ? column.filterable : true,
      } as InternalTableColumn;
    });

    this.loadColumns();

    if (this.initialSorting) {
      this.sortState = {
        columnKey: this.initialSorting.columnKey,
        ascending: this.initialSorting.ascending,
      };
    }

    if (!this.service) {
      this.loadData().subscribe();
    }
  }

  public toggleFullScreen() {
    this.isFullScreenActive = !this.isFullScreenActive;
    this.fullScreenToggle.emit(this.isFullScreenActive);
  }

  public loadColumns(): void {
    const localStorageColumns: DisplayableTableColumn[] =
      this.tableStorageService.getColumns(
        this.uniqueTableName,
        this.internalTableColumns.map((column) => ({
          propertyKey: column.propertyKey,
          isVisible: true,
        })),
      );

    this.internalTableColumns.forEach((column) => {
      column.isVisible =
        localStorageColumns.find(
          (col) => col.propertyKey === column.propertyKey,
        )?.isVisible ?? true;
    });
  }

  toggleColumnVisibility(event: MouseEvent): void {
    DialogComponent.show(
      TableConfigurationComponent,
      this.applicationRef,
      {
        title: 'general.table-configure',
        columns: this.internalTableColumns,
        tableService: this.tableStorageService,
        translationKeyPrefix: this.translationKeyPrefix,
        uniqueTableName: this.uniqueTableName,
        hasCancelButton: false,
        hasOutsideDialogClickListener: true,
        isFullScreenActive: this.isFullScreenActive,
      },
      event.target as HTMLElement,
    ).subscribe(() => this.loadColumns());
  }

  public reload(context?: object): void {
    this.lastServiceContextParam = context;
    this.loadData().subscribe();
  }

  public reloadAsObservable(
    context?: object,
  ): Observable<PagedData<unknown> | unknown[]> {
    this.lastServiceContextParam = context;
    return this.loadData();
  }

  private loadData(page = 0): Observable<PagedData<unknown> | unknown[]> {
    if (page === 0) {
      this.rows = [];
      this.selectedRows = [];
      this.totalRowCount = 0;
      this.firstRowIndex = 0;
    }

    const query = this.service ? this.buildQuery() : null;
    this.dataLoading.emit(query);
    if (this.service) {
      return this.service
        .loadData(
          { page: page + 1, size: this.pageSize },
          query as Query,
          this.lastServiceContextParam ?? this.serviceContext,
        )
        .pipe(
          tap((data) => {
            if (page === 0) {
              this.rows = [];
              this.selectedRows = [];
              this.totalRowCount = 0;
              this.firstRowIndex = 0;
            }

            this.rows = data.content;
            this.totalRowCount = data.totalSize;
            this.firstRowIndex = page * this.pageSize;
            this.dataLoaded.emit();
          }),
        );
    } else if (this.dataItems$) {
      return this.dataItems$.pipe(
        tap((data) => {
          this.rows = data;
          this.firstRowIndex = 0;
          this.totalRowCount = data.length;
          if (this.sortState.columnKey) {
            const key = this.sortState.columnKey;
            this.rows = this.rows.sort(this.compareFn(key));
          }

          this.dataLoaded.emit();
        }),
      );
    } else {
      throw new Error(
        'You have to pass either a service or a dataItem$ observable.',
      );
    }
  }

  public onDropdownChange() {
    if (!this.pageDropdown) {
      return;
    }

    this.onLazyLoad({ first: 0 });
  }

  public onRowSelectorClick(index: number): void {
    if (this.selectedRows.includes(index)) {
      this.selectedRows = this.selectedRows.filter((r) => r !== index);
    } else {
      this.selectedRows.push(index);
    }
  }

  public onAllSelectorClick(): void {
    if (this.selectedRows.length === this.rows.length) {
      this.selectedRows = [];
    } else {
      this.selectedRows = [...Array(this.rows.length).keys()];
    }
  }

  public onLazyLoad(state: TableLazyLoadEvent): void {
    if (
      this.service &&
      (!this.skipInitialLoad ||
        this.lastServiceContextParam ||
        this.serviceContext)
    ) {
      this.loadData((state.first ?? 0) / this.pageSize).subscribe();
    }
  }

  public onSort(column: InternalTableColumn): void {
    const key = column.filterKey || column.propertyKey;
    if (this.sortState.columnKey !== key) {
      this.sortState = { columnKey: key, ascending: true };
    } else if (this.sortState.ascending) {
      this.sortState.ascending = false;
    } else if (!this.sortState.ascending) {
      this.sortState = { columnKey: '', ascending: false };
    }

    this.loadData().subscribe();
  }

  public setFilter(
    column: InternalTableColumn,
    selectedValue: unknown[],
    context: object,
  ): void {
    this.lastServiceContextParam = context;
    this.onFilter(column, selectedValue);
  }

  private onFilter(column: InternalTableColumn, value: unknown): void {
    const old = JSON.stringify(this.filterState);
    const key = column.filterKey || column.propertyKey;
    if (!value || (Array.isArray(value) && !value.length)) {
      delete this.filterState[key];
    } else {
      this.filterState[key] = value;
    }
    if (old !== JSON.stringify(this.filterState)) {
      this.loadData().subscribe();
    }
  }

  private buildQuery(): Query {
    const query = {
      where: { AND: [] as Condition[], OR: [] as Condition[] } as Where,
      sorting: [] as Sorting[],
    } as Query;

    Object.keys(this.filterState).forEach((key) => {
      query.where.AND.push({
        key,
        operator: Array.isArray(this.filterState[key]) ? 'in' : 'contains',
        value: this.filterState[key],
      });
    });

    if (this.sortState.columnKey) {
      const s: Sorting = {};
      s[this.sortState.columnKey] = this.sortState.ascending ? 'ASC' : 'DESC';
      query.sorting.push(s);
    }
    return query;
  }

  public openFilterDialog(evt: MouseEvent): void {
    DialogComponent.show(
      TableFilterDialogComponent,
      this.applicationRef,
      {
        title: 'general.table-filter',
        filterState: this.filterState,
        columns: this.visibleColumns,
        service: this.service,
        serviceContext: this.lastServiceContextParam ?? this.serviceContext,
        translationKeyPrefix: this.translationKeyPrefix,
        cancelButtonText: 'general.reset',
        hasOutsideDialogClickListener: true,
        isFullScreenActive: this.isFullScreenActive,
      },
      evt.target as HTMLElement,
    )
      .pipe(
        tap((result) => (this.filterState = result)),
        switchMap(() => this.loadData()),
      )
      .subscribe();
  }

  private compareFn(key: any) {
    return (a: any, b: any) => {
      const valA = (a as any)[key];
      const valB = (b as any)[key];
      let result = 0;

      if (!valA) {
        result = valB ? -1 : 0;
      } else if (!valB) {
        result = 1;
      } else if (typeof valA === 'string') {
        result = valA.localeCompare(valB);
      } else if (typeof valA === 'number') {
        result = valA - valB;
      } else if (typeof valA === 'boolean') {
        result = valA ? 1 : valB ? -1 : 0;
      } else if (valA instanceof Date) {
        result = valA.getDate() - valB.getDate();
      }

      const asc = this.sortState.ascending ? 1 : -1;

      return asc * result;
    };
  }
}
