import { Component, Input } from '@angular/core';

@Component({
  selector: 'th-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.scss'],
})
export class CardLayoutComponent {
  @Input() layout: 'one-column' | 'two-columns' = 'two-columns';
  @Input() fullWidth = false;

  public get classes(): string {
    return this.layout === 'one-column'
      ? this.fullWidth
        ? 'flex flex-col gap-4'
        : 'flex flex-col gap-4 mr-6 w-1/2'
      : 'grid grid-cols-2 gap-4 mr-6';
  }
}
