import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { NavigationItem } from '../models/navigation-item.model';
import {
  NavigationService,
  RouteNameResolver,
} from '../services/navigation.service';

@Component({
  selector: 'th-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit {
  @Input() nameResolver!: RouteNameResolver | null;

  public navigationItems!: NavigationItem[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private destroyRef: DestroyRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.navigationService
      .getBreadcrumbsFromRoute(this.activatedRoute, 'budget', this.nameResolver)
      .pipe(
        tap((items) => (this.navigationItems = items)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  navigate(link: string): void {
    this.router.navigateByUrl(`/${link}`);
  }
}
