<div
  class="flex h-4 flex-1 items-stretch justify-start rounded-md overflow-hidden"
>
  <div
    *ngFor="let bar of percentageBars"
    class="flex justify-center items-center text-black"
    [style.flex-basis]="bar.percentage + '%'"
    [style.background-color]="bar.color"
    [tp]="((bar.value | numberFormat: false : true) ?? '') + (bar.suffix || '')"
  >
    {{ showInlineValues ? bar.text : '' }}
  </div>
</div>

<div *ngIf="showLegend" class="flex gap-8 mt-2">
  <div *ngFor="let bar of percentageBars" class="flex items-center gap-2">
    <div [style.background-color]="bar.color" class="h-2 w-2"></div>
    <div class="flex flex-col">
      <span class="text-left text-sm">{{ bar.text }}</span>
      <span class="text-left text-base">{{
        ((bar.value | numberFormat: false : true) ?? '') + (bar.suffix || '')
      }}</span>
    </div>
  </div>
</div>
