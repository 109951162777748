import { CdkConnectedOverlay, Overlay } from '@angular/cdk/overlay';
import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NavigationEnd,
  NavigationSkipped,
  Router,
  Scroll,
} from '@angular/router';
import { Observable, filter, map, switchMap, tap } from 'rxjs';
import { NavigationItem } from '../models/navigation-item.model';
import { CollapseSidebarService } from '../services/collapse-sidebar.service';
import { HelperService } from '../services/helper.service';
import {
  NavigationService,
  RouteNameResolver,
  TownhallApplication,
} from '../services/navigation.service';
import { RoleService } from '../services/role.service';
import { StorageService } from '../services/storage.service';
import { ThemeService } from '../services/theme.service';
import { UserState } from '../state/user.state';

import { theme } from 'tailwindcss/defaultConfig';

@Component({
  selector: 'th-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input({ required: true }) app!: TownhallApplication;
  @Input({ required: true }) nameResolver!: RouteNameResolver;

  public isProfileMenuVisible = false;
  private scopedNavigationItems: NavigationItem[] = [];
  private globalNavigationItems: NavigationItem[] = [];
  private menuToggleElement!: Element;
  private connectedOverlay!: CdkConnectedOverlay;

  private mediaWidthCollapseThreshold = 1024;
  private overThreshold = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (
      event.target.innerWidth < this.mediaWidthCollapseThreshold &&
      !this.overThreshold
    ) {
      this.collapseSidebarService.set(true);
      this.overThreshold = true;
    } else if (
      event.target.innerWidth >= this.mediaWidthCollapseThreshold &&
      this.overThreshold
    ) {
      this.overThreshold = false;
      this.collapseSidebarService.set(
        this.collapseSidebarService.isUserCollapse,
      );
    }
  }

  public get titleKey(): string {
    return `general.${this.app}-app-title`;
  }

  public get navigationItemsKey(): string {
    return `general.${this.app}-navigation-title`;
  }

  public get emulatedFullName$(): Observable<string> {
    return this.userState.userInfo$.pipe(
      map((info) =>
        info ? `${info.emulatedFirstName} ${info.emulatedLastName}`.trim() : '',
      ),
    );
  }

  public get fullName$(): Observable<string> {
    return this.userState.userInfo$.pipe(
      map((info) => (info ? `${info.firstName} ${info.lastName}`.trim() : '')),
    );
  }

  public get emailAddress$(): Observable<string> {
    return this.userState.userInfo$.pipe(
      map((info) => (info ? `${info.email}`.trim() : '')),
    );
  }

  public get profilePicture$(): Observable<string> {
    return this.userState.profilePicture$;
  }

  public get filteredScopedItems(): NavigationItem[] {
    return this.scopedNavigationItems
      .filter((item) => this.roleService.isAuthorized(item.neededRoles))
      .map((item) => {
        item.childs = item.childs?.filter((child) =>
          this.roleService.isAuthorized(child.neededRoles),
        );
        return item;
      });
  }

  public get filteredGlobalItems(): NavigationItem[] {
    return this.globalNavigationItems.filter((item) =>
      this.roleService.isAuthorized(item.neededRoles),
    );
  }

  public get isSidebarCollapsed(): boolean {
    return this.collapseSidebarService.isSidebarCollapsed;
  }

  @ViewChild('menuToggleRef') set approvalToggleButtonRef(
    approvalToggleButtonRef: ElementRef<HTMLElement>,
  ) {
    this.menuToggleElement = approvalToggleButtonRef?.nativeElement;
  }

  @ViewChild('cdkConnectedOverlay') set cdkConnectedOverlay(
    connectedOverlay: CdkConnectedOverlay,
  ) {
    this.connectedOverlay = connectedOverlay;
  }

  constructor(
    public userState: UserState,
    public themeService: ThemeService,
    public helperService: HelperService,
    public collapseSidebarService: CollapseSidebarService,
    private router: Router,
    private roleService: RoleService,
    public storageService: StorageService,
    private navigationService: NavigationService,
    private destroyRef: DestroyRef,
    private overlay: Overlay,
  ) {
    if (theme?.screens) {
      const mediaBreakPoints = Object.entries(theme.screens);
      const lgBreakPoint = mediaBreakPoints.find(([key]) => key === 'lg');

      if (lgBreakPoint) {
        this.mediaWidthCollapseThreshold = parseInt(lgBreakPoint[1]);
      }
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof NavigationSkipped ||
            event instanceof Scroll,
        ),
        switchMap(() =>
          this.navigationService.getNavigationItemsFromRouterState(
            this.router.routerState,
            this.router.config,
            this.app,
            this.nameResolver,
          ),
        ),
        tap((items) => (this.scopedNavigationItems = items)),
        switchMap(() =>
          this.navigationService.getNavigationItemsFromRouterState(
            this.router.routerState,
            this.router.config,
            'global',
            this.nameResolver,
          ),
        ),
        tap(
          (items) =>
            (this.globalNavigationItems = items.filter(
              (x) => !x.link?.startsWith('global') && x.link !== this.app,
            )),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public logout(): void {
    this.userState.logout();
    this.router.navigate(['login']);
  }

  goToHome(): void {
    this.router.navigate([this.app === 'global' ? '/' : `/${this.app}`]);
  }

  navigate(link: string): void {
    this.router.navigateByUrl(`/${link}`);
  }

  stopEmulation(): void {
    this.userState.stopEmulation();
  }

  toggleSidebar(): void {
    if (this.overThreshold) {
      this.collapseSidebarService.isUserCollapse =
        !this.collapseSidebarService.isSidebarCollapsed;
      this.collapseSidebarService.set(
        this.collapseSidebarService.isUserCollapse,
      );
    } else {
      this.collapseSidebarService.toggleUserCollapse();
      this.collapseSidebarService.set(
        this.collapseSidebarService.isUserCollapse,
      );
    }
  }

  public toggleMenu(): void {
    this.isProfileMenuVisible = !this.isProfileMenuVisible;

    setTimeout(() => {
      if (
        this.isProfileMenuVisible &&
        this.connectedOverlay?.overlayRef &&
        this.menuToggleElement
      ) {
        this.connectedOverlay.overlayRef.updatePositionStrategy(
          this.overlay
            .position()
            .flexibleConnectedTo(this.menuToggleElement)
            .withPush(true)
            .withViewportMargin(20)
            .withDefaultOffsetX(30)
            .withPositions([
              {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
              },
            ]),
        );
        this.connectedOverlay.overlayRef.updatePosition();
      }
    });
  }
}
