<dialog
  [class]="
    dialogData.isFullScreenActive ? 'relative z-100000001' : 'relative z-50'
  "
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
  #dialogRef
>
  <div class="fixed inset-0 bg-gray-50 bg-opacity-40 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto dialog-content-container">
    <div
      class="flex items-end p-4 text-center sm:items-center sm:p-0"
      [class.min-h-full]="!calculatedDialogPosition"
      [class.justify-center]="!calculatedDialogPosition"
      [class.relative]="calculatedDialogPosition"
      [ngClass]="customWidth ? customWidth : ''"
      [ngStyle]="{
        top: calculatedDialogPosition ? calculatedDialogPosition.y + 'px' : '',
        left: calculatedDialogPosition ? calculatedDialogPosition.x + 'px' : ''
      }"
    >
      <div
        class="relative transform rounded-lg bg-dark-gray-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 dialog-content"
      >
        <div class="sm:flex sm:items-start">
          <div
            *ngIf="icon"
            class="icon mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
            [ngClass]="dialogData.type"
          >
            <span class="material-symbols-outlined">{{ icon }}</span>
          </div>
          <div
            class="mt-3 w-full text-center sm:mt-0 sm:text-left"
            [class.sm:ml-4]="icon"
          >
            <h3
              class="text-base font-semibold leading-6"
              id="modal-title"
              [class.text-warning]="isTitleWarning"
            >
              {{ dialogData.title | translate }}
            </h3>
            <div
              *ngIf="hasSpacer"
              class="border border-dark-gray-750 my-2.5"
            ></div>
            <div class="mt-2">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <th-button
            *ngIf="hasConfirmButton"
            class="sm:ml-3"
            [type]="dialogData.type === 'error' ? 'error' : 'primary'"
            (click)="confirm.emit()"
            [isDisabled]="!isConfirmAllowed"
            [text]="
              dialogData.confirmButtonText || 'general.confirm' | translate
            "
            tabindex="-1"
          >
          </th-button>
          <th-button
            *ngIf="dialogData.hasCancelButton"
            type="gray"
            (click)="cancel.emit()"
            [text]="dialogData.cancelButtonText || 'general.cancel' | translate"
            tabindex="-1"
          >
          </th-button>
        </div>
      </div>
    </div>
  </div>
</dialog>
